import getCustomerIdFromPath from "@/lib/paths/getCustomerIdFromPath";
import { AdminGlobalVarContext } from "@/providers/data/Common/Admin/GlobalVarProvider";
import { Navigation } from "@/types/type";
import {
  UserGroupIcon,
  HomeIcon,
  CalendarDaysIcon,
  DocumentTextIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  ChartBarIcon,
  DocumentCheckIcon,
  UserCircleIcon,
  SparklesIcon,
  DocumentMagnifyingGlassIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useContext } from "react";

export default function useSecondarySidebar(): Navigation[] | undefined {
  const router = useRouter();

  const { shops } = useContext(AdminGlobalVarContext);

  const customerId = getCustomerIdFromPath(router.asPath);

  const customerRootNavigation = getCustomerRootNavigation(router.asPath);
  const customerDetailNavigation = getCustomerDetailNavigation(router.asPath, customerId);
  const generalNavigation = getGeneralNavigation(router.asPath, shops);

  if (shops.length <= 1) {
    return undefined;
  }

  const secondaryNavigation: Navigation[] = [
    ...customerRootNavigation,
    ...customerDetailNavigation,
    ...generalNavigation,
  ];

  return secondaryNavigation;
}

function getCustomerRootNavigation(path: string): Navigation[] {
  const navigation = [
    {
      name: "塾生",
      href: "/admin/customers",
      current: path === "/admin/customers",
      icon: UserGroupIcon,
    },
  ];
  return navigation;
}

function getCustomerDetailNavigation(path: string, customerId: string | undefined): Navigation[] {
  if (!customerId) {
    return [];
  }

  const customerDetailNavigation: Navigation[] = [
    {
      name: "塾生詳細",
      href: `/admin/customers/${customerId}`,
      current: path === `/admin/customers/${customerId}` || path.startsWith(`/admin/customers/${customerId}?`),
      icon: HomeIcon,
      indentLevel: 1,
    },
    {
      name: "担当トレーナー履歴",
      href: `/admin/customers/${customerId}/trainers`,
      current: path.startsWith(`/admin/customers/${customerId}/trainers`),
      icon: UserCircleIcon,
      indentLevel: 1,
    },
    {
      name: "トレーニングレコード",
      href: `/admin/customers/${customerId}/training-record`,
      current:
        path === `/admin/customers/${customerId}/training-record` ||
        path.startsWith(`/admin/customers/${customerId}/training-record?`),
      icon: UserGroupIcon,
      indentLevel: 1,
      // only: ["hq"],
    },
    {
      name: "体組成記録",
      href: `/admin/customers/${customerId}/training-record/body-composition`,
      current: path.startsWith(`/admin/customers/${customerId}/training-record/body-composition`),
      icon: CalendarDaysIcon,
      indentLevel: 1,
      // only: ["hq"],
    },
    {
      name: "電子カルテ",
      href: `/admin/customers/${customerId}/training-record/counseling-sheet`,
      current: path.startsWith(`/admin/customers/${customerId}/training-record/counseling-sheet`),
      icon: DocumentTextIcon,
      indentLevel: 1,
      // only: ["hq"],
    },
    // {
    //   name: "物販履歴",
    //   href: `/admin/customers/${customerId}/goods-sales-history`,
    //   current: path.startsWith(`/admin/customers/${customerId}/goods-sales-history`),
    //   icon: CreditCardIcon,
    //   indentLevel: 1,
    //   only: ["hq"],
    // },
    {
      name: "旧システム売上明細",
      href: `/admin/customers/${customerId}/old-sales`,
      current: path.startsWith(`/admin/customers/${customerId}/old-sales`),
      icon: CreditCardIcon,
      indentLevel: 1,
      only: ["hq"],
    },
  ];

  return customerDetailNavigation;
}

function getGeneralNavigation(path: string, shops: { id: number; name: string }[]): Navigation[] {
  if (shops.length === 0) {
    return [];
  }

  const navigation: Navigation[] = [
    {
      name: "最新の予約",
      href: "/admin/reserves",
      current: path.startsWith("/admin/reserves"),
      icon: CalendarDaysIcon,
    },
    {
      name: "塾生の支払い",
      href: "/admin/payments",
      current: path.startsWith("/admin/payments"),
      icon: CreditCardIcon,
    },
    {
      name: "未払いのプラン決済",
      href: "/admin/unpaid",
      current: path.startsWith("/admin/unpaid"),
      icon: ExclamationCircleIcon,
    },
    {
      name: "売上グラフ",
      href: "/admin/sales",
      current: path.split("?")[0] === "/admin/sales",
      icon: ChartBarIcon,
    },
    {
      name: "支払通知書",
      href: "/admin/reports",
      current: path.startsWith("/admin/reports"),
      icon: DocumentMagnifyingGlassIcon,
      only: ["hq"],
    },
    {
      name: "日報",
      href: "/admin/daily-reports",
      current: path.startsWith("/admin/daily-reports"),
      icon: DocumentCheckIcon,
    },
    {
      name: "トレーナー",
      href: "/admin/trainers",
      current: path.startsWith("/admin/trainers"),
      icon: UserCircleIcon,
    },
    {
      name: "オーナー",
      href: "/admin/owners",
      current: path.startsWith("/admin/owners"),
      icon: SparklesIcon,
      only: ["hq"],
    },
    {
      name: "Shopify注文履歴",
      href: "/admin/shopify-orders",
      current: path.startsWith("/admin/shopify-orders"),
      icon: DocumentCheckIcon,
      only: ["hq", "owner", "trainer"],
    },
    {
      name: "失効プランチケット",
      href: `/admin/expired-plan-tickets`,
      current: path.startsWith(`/admin/expired-plan-tickets`),
      icon: DocumentCheckIcon,
      only: ["hq"],
    },
    {
      name: "お知らせ",
      href: `/admin/notifications`,
      current: path.startsWith(`/admin/notifications`),
      icon: MegaphoneIcon,
      only: ["hq"],
    },
    {
      name: "電子カルテ追跡",
      href: `/admin/counseling-sheet-tracking`,
      current: path.startsWith(`/admin/counseling-sheet-tracking`),
      icon: DocumentTextIcon,
      only: ["hq", "owner", "trainer"],
    },
    {
      name: "友達紹介CV数",
      href: `/admin/customer-invitation-count`,
      current: path.startsWith(`/admin/customer-invitation-count`),
      icon: UserGroupIcon,
      only: ["hq", "owner", "trainer"],
    },
    // {
    //   name: "データ操作",
    //   href: "/admin/data-operations",
    //   current: path.startsWith("/admin/data-operations"),
    //   icon: WrenchIcon,
    // },
  ];
  return navigation;
}
